export class ValidationError extends Error {
  caseCode?: number
  response?: any

  constructor (name: string, message: string, caseCode?: number) {
    super(message)

    this.name = name
    this.caseCode = caseCode
  }
}
