import { defineNuxtRouteMiddleware, navigateTo, useError } from '#app'
import { useAccount } from '@/composables/useAccount'

export default defineNuxtRouteMiddleware((to) => {
  const error = useError()

  let path = to.path
  let query = to.query

  const redirects: Array<[RegExp, string]> = [
    [/\/index(\.php)?\/?/i, '/'],
    [/^\/wine-tasting\/?/i, '/pages/wine-tasting.html'],
    [/^\/catalog(?=\/[^?])/i, ''],

    // Блог
    [/^\/pages\/articles(\/|\.html)?/i, '/articles/'],
    [/^\/articles\/(\w|-)+(?=\/tags\/(\w|-)+\/?)/i, '/articles']
  ]

  for (let i = 0; i < redirects.length; i++) {
    const replacement = redirects[i]
    path = path.replace(replacement[0], replacement[1])
  }

  // Главная
  if (to.path === '/' && to.name === 'index') {
    const { isAdmin } = useAccount()
    const allowedQueryParams: string[] = ['search_query', 'gulp_critical_gen', 'frommarket', 'ymclid', 'perf']

    if (!isAdmin.value) {
      query = Object.fromEntries(Object.entries(query).filter(([key]) => allowedQueryParams.includes(key)))
    }
  }

  // Продуктовая
  const productsRegExp = /^\/products(?:\/products)+\//i
  if (productsRegExp.test(to.path) && !useDevice().isCrawler.value) {
    return navigateTo({ path: to.path.replace(productsRegExp, '/products/') }, { redirectCode: 200 })
  }

  if (to.path !== path || Object.keys(to.query).length !== Object.keys(query).length) {
    return navigateTo({ path, query }, { redirectCode: 301 })
  }

  if (!error.value && !(to.path.includes('.html') || to.path.includes('.php') || to.path.slice(-1) === '/')) {
    return navigateTo({ path: `${to.path}/`, query }, { redirectCode: 301 })
  }
})
