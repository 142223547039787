import { useCustomerStore } from '@/stores/customer'
import { setModalShopOptions } from '@/utils/modal-shop-options'
import { useCartStore } from '@/stores/cart'
import { useFavoritesStore } from '@/stores/favorites'

type AppSetupReturnType = {
  serverSetup: () => Promise<void>
  clientSetup: () => void
}

export const appSetup = (): AppSetupReturnType => {
  const serverSetup: AppSetupReturnType['serverSetup'] = async () => {
    setModalShopOptions()
  }

  const clientSetup: AppSetupReturnType['clientSetup'] = async () => {
    const route = useRoute()

    if (import.meta.client) {
      const { getCartBase } = useCartStore()
      const { getWishListProductIds } = useFavoritesStore()
      const { initCustomer, isVerified } = useCustomerStore()

      const promises = [
        initCustomer(),
        getWishListProductIds()
      ]

      if (route.name !== 'cart.php') {
        promises.push(getCartBase())
      }

      await Promise.all(promises)

      if (
        (isVerified.value && route.name === 'login') ||
        (!isVerified.value && route.name === 'private_office-profile')
      ) {
        useRouter().replace('/')
      }
    }
  }

  return { serverSetup, clientSetup }
}
