<template>
  <Html :lang="locale">
    <div v-if="isTimedOutError" class="error-page">
      <div class="error-page__container container">
        <div class="сontent">
          <!-- todo check ssr -->
          <AImage
            :src="getErrorImg"
            width="288"
            height="180"
            class="error-page__image"
            from-public
          />
          <AHeading
            :level="2"
            size="5xl"
            class="error-page__title"
          >
            {{ getTitle(error.statusCode) }}
          </AHeading>
          <div class="error-page__text">
            {{ getText(error.statusCode) }}
          </div>
        </div>
        <AButton
          size="lg"
          class="error-page__button"
          @click="handleReload()"
        >
          {{ $t("pages.error.refresh") }}
        </AButton>
      </div>
    </div>
    <NuxtLayout v-else>
      <div
        class="error-page"
        :class="{ 'error-bg': error.statusCode === 401 }"
      >
        <div
          v-if="error.statusCode !== 401"
          class="error-page__container container"
        >
          <div class="сontent">
            <!-- todo check ssr -->
            <AImage
              :src="getErrorImg"
              width="288"
              height="180"
              class="error-page__image"
              from-public
            />
            <AHeading
              :level="2"
              size="5xl"
              class="error-page__title"
            >
              {{ getTitle(error.statusCode) }}
            </AHeading>
            <div class="error-page__text">
              {{ getText(error.statusCode) }}
            </div>
          </div>
          <AButton
            size="lg"
            class="error-page__button"
            @click="handleClearError({ redirect: '/' })"
          >
            {{ $t("pages.error.button") }}
          </AButton>
        </div>
        <Suspense v-else>
          <LazyOLoginForm :error="true" />
        </Suspense>

        <!-- <div
              v-if="error.statusCode === 404 || error.statusCode === 400"
              class="error-page__container-full container"
            >
              <RenderCacheable
                :max-age="3600"
                :cache-key="defaultCacheKey"
                :async-data-keys="['reasons-categories-default']"
              >
                <PopularCategories :title="$t('organisms.catalog.popular-categories')" />
              </RenderCacheable>

              <RenderCacheable
                :max-age="3600"
                :cache-key="defaultCacheKey"
                :async-data-keys="['error-buy-again-products']"
              >
                <ErrorProductCarousel />
              </RenderCacheable>
            </div> -->
      </div>
    </NuxtLayout>
  </Html>
</template>

<script lang="ts" setup>
import { computed, onMounted, onBeforeUnmount, defineAsyncComponent } from 'vue'
import {
  clearError
  // useNuxtApp
} from '#app'
import { useLocalization } from '#imports'
import { appSetup } from '@/utils/app'

import type { NuxtError } from '#app'
import type { PropType } from 'vue'

import AButton from '@/components/atoms/Button/AButton.vue'
import AImage from '@/components/atoms/Image/AImage.vue'
import AHeading from '@/components/atoms/Heading/AHeading.vue'

// import ErrorProductCarousel from '@/components/business/error/ErrorProductCarousel.vue'
// import PopularCategories from '@/components/business/categories/PopularCategories.vue'

const LazyOLoginForm = defineAsyncComponent(() => /* @vite-ignore */ import('@/components/organisms/LoginForm/OLoginForm.vue'))

const props = defineProps({
  error: {
    type: Object as PropType<Partial<NuxtError>>,
    default: () => {}
  }
})

// const defaultCacheKey = createKey()

const { locale, t } = useLocalization()
// const { pageErrorLogger } = useNuxtApp().$sentry

const isTimedOutError = computed(() => props.error.statusCode === 503)

const handleClearError = (options?: { redirect: string }): void => {
  clearError(options)
}

const handleReload = (): void => {
  if (window.navigator.onLine) {
    window.location.reload()
  }
}

const { serverSetup, clientSetup } = appSetup()

if (import.meta.server && !isTimedOutError.value) {
  await serverSetup()
}

const getTitle = (statusCode?: number): string => {
  switch (statusCode) {
    case 400:
      return t('pages.error.400')
    case 401:
      return 'Unauthorized'
    case 403:
      return t('pages.error.500')
    case 404:
      return t('pages.error.404')
    case 500:
      return t('pages.error.500')
    case 503:
      return 'Service Unavailable'
    default:
      return 'Error'
  }
}

const getText = (statusCode?: number): string => {
  switch (statusCode) {
    case 400:
      return props.error.statusMessage ?? props.error.message ?? 'API Error'
    case 401:
      return 'Unauthorized'
    case 403:
      return t('pages.error.500_text')
    case 404:
      return t('pages.error.404_text')
    case 500:
      return t('pages.error.500_text')
    case 503:
      return props.error.statusMessage ?? props.error.message ?? 'Service Unavailable'
    default:
      return 'Error'
  }
}

// FIXME для статуса 503 нет изображения, добавлена заглушка
const getErrorImg = computed(() => {
  const statusCode = isTimedOutError.value ? 'no-conect' : props.error.statusCode
  return `/assets/images/${statusCode}.svg`
})

onMounted(() => {
  // pageErrorLogger(props.error)
  if (props.error.statusCode && props.error.statusCode < 500) {
    clientSetup()
  }
})

onBeforeUnmount(handleClearError)

useSeoMeta({
  title: props.error.statusCode === 404 ? 'WineStyle - Not Found' : undefined,
  description: props.error.statusCode === 404 ? t('others.seo-description') : undefined
})
</script>

<style lang="postcss">
.error-bg {
  background: var(--color-neutral-200);
}

.error-page {
  padding-top: var(--spacer-5xl);
  padding-bottom: var(--spacer-5xl);

  &__image {
    display: block;

    & img {
      height: 180px;
    }
  }

  &__button {
    width: fit-content;
    min-width: 12.5rem;
    margin: auto;
  }

  &__title {
    display: block;
    justify-content: center;
    width: 100%;
    max-width: fit-content;
    margin-top: var(--spacer-base);
    margin-bottom: var(--spacer-3xs);
    text-align: center;

    @mixin text-4xl;

    @media (--screen-lg) {
      @mixin text-5xl;
    }
  }

  &__text {
    max-width: 29.75rem;
    margin: 0 auto;
    color: var(--color-text-dark);
    text-align: center;

    @mixin text-base;
  }

  .error-page__container {
    display: flex;
    flex-direction: column;

    &-full {
      margin-top: 7.5rem;
      padding: 0 var(--spacer-xs);

      @media (--screen-lg) {
        padding: 0 var(--spacer-lg);
      }
    }
  }

  .popular-category {
    @media (--screen-xs) {
      height: 8.5rem;
    }
  }

  .сontent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--spacer-base);
    text-align: center;
  }
}
</style>
