import { defineNuxtPlugin } from '#app'
import { useContextData } from '#imports'

import type { BaseInterceptor, InterceptorInvoker, InterceptorRequest } from '../helpers/interceptor'

export default defineNuxtPlugin((nuxt) => {
  const apiInterceptors = useContextData<any>('api-interceptors', [])

  class NetworkInterceptor implements BaseInterceptor {
    intercept (request: InterceptorRequest, invoker: InterceptorInvoker) {
      if (!window.navigator.onLine) {
        throw nuxt.runWithContext(() => showError({ message: 'Network unavailable', statusCode: 503, fatal: true }))
      }

      return invoker(request)
    }
  }

  apiInterceptors.value.push(new NetworkInterceptor())
})
