import { defineNuxtPlugin, useRuntimeConfig } from '#app'
import { useContextData } from '#imports'
import { useConfig } from '~/stores/config'

import type { BaseInterceptor, InterceptorInvoker, InterceptorRequest } from '../helpers/interceptor'

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig().public
  const apiInterceptors = useContextData<any>('api-interceptors', [])

  const { currentRegion } = useConfig()
  const { locale } = useLocalization()

  class MetadataInterceptor implements BaseInterceptor {
    intercept (request: InterceptorRequest, invoker: InterceptorInvoker) {
      const deadline = new Date()
      deadline.setSeconds(deadline.getSeconds() + runtimeConfig.apiGrpcTimeoutInS)

      let lang = 'rus'

      switch (locale.value) {
        case 'en':
          lang = 'eng'
          break
      }

      request.getMetadata()['Accept-Language'] = lang
      request.getMetadata()['X-Metadata-Region'] = currentRegion.value?.id.toString()
      request.getMetadata()['deadline'] = deadline.getTime().toString()

      return invoker(request)
    }
  }

  apiInterceptors.value.push(new MetadataInterceptor())
})
