import { defineNuxtPlugin } from '#app'
import { useHydrationRef, useContextData, useLocalStorage } from '#imports'
import type { BaseInterceptor, InterceptorInvoker, InterceptorRequest } from '~/modules/nuxt-api/helpers/interceptor'

export default defineNuxtPlugin(() => {
  const apiInterceptors = useContextData<any>('api-interceptors', [])

  const csrfToken = useHydrationRef('csrf-token', '')
  const token = useLocalStorage('csrf-token')

  token.value = csrfToken.value

  class CsrfInterceptor implements BaseInterceptor {
    intercept (request: InterceptorRequest, invoker: InterceptorInvoker) {
      if (token.value) {
        request.getMetadata()['X-csrf-token'] = token.value
      }

      return invoker(request)
    }
  }

  apiInterceptors.value.push(new CsrfInterceptor())
})
