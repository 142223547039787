import { defineNuxtPlugin } from '#app'

import { useNotify } from '#imports'
import { GrpcError } from '../models/errors/grpc-error'
import { UnauthorizedError } from '../models/errors/unauthorized-error'
import { ServerError } from '../models/errors/server-error'
import { RedirectError } from '../models/errors/redirect-error'
import { SectionError } from '../models/errors/section-error'
import { ValidationError } from '../models/errors/validation-error'

import type { BaseInterceptor, InterceptorInvoker, InterceptorRequest } from '../helpers/interceptor'

export default defineNuxtPlugin((nuxt) => {
  const apiInterceptors = useContextData<any>('api-interceptors', [])

  class ErrorInterceptor implements BaseInterceptor {
    async intercept (request: InterceptorRequest, invoker: InterceptorInvoker) {
      const responseCases = request.getRequestMessage().responseCases
      const responseCheckValidation = request.getRequestMessage().checkValidation
      const responseCheckRedirect = request.getRequestMessage().checkRedirect
      const responseCheckProductNotFound = request.getRequestMessage().checkProductNotFound
      const responseCheckShopNotFound = request.getRequestMessage().checkShopNotFound
      const responseName = request.getMethodDescriptor().getName()

      const validCases = [
        responseCases?.DATA,
        responseCases?.RESPONSE_NOT_SET
      ]

      async function getResponse () {
        try {
          return await invoker(request)
        } catch (err: any) {
          throw new GrpcError('ApiError: GRPC ERROR', err.message + ' (' + responseName + ')', err.code)
        }
      }

      // function checkResponseServerError (currentCase: number) {
      //   if ([2, 13].includes(currentCase)) {
      //     throw new ServerError('ApiError: SERVER ERROR', '[' + currentCase + '] ' + responseName, currentCase)
      //   }
      // }

      try {
        const response = await getResponse()
        const currentCase = response.getResponseMessage().getResponseCase()

        const sectionErrorCase = responseCases?.INVALID_SECTION_ERROR || -1
        const validationErrorCase = responseCases?.VALIDATION_ERROR || -1
        const productNotFoundCase = responseCases?.PRODUCT_NOT_FOUND_ERROR || -1
        const shopNotFoundCase = responseCases?.SHOP_NOT_FOUND_ERROR || -1

        if (responseCases) {
          if (currentCase === sectionErrorCase) {
            // @ts-expect-error
            const redirectPath = response.getResponseMessage().getInvalidSectionError?.().toObject?.()?.redirectLocation?.path

            if (responseCheckRedirect && currentCase === sectionErrorCase) {
              if (redirectPath) {
                throw new RedirectError(`Redirect ${responseName}`, redirectPath)
              }

              const error = new SectionError('ApiError: SECTION ERROR', responseName, currentCase)

              error.response = response?.getResponseMessage()

              throw error
            }

            return response
          }

          if (responseCheckValidation && currentCase === validationErrorCase) {
            const error = new ValidationError('ApiError: VALIDATION ERROR', responseName, currentCase)
            throw error
          }

          // if (meta['grpc-status']) {
          //   checkResponseServerError(parseInt(meta['grpc-status']))
          // }

          // @ts-expect-error
          if (response?.getResponseMessage().toObject().unauthorizedError) {
            throw new UnauthorizedError('UnauthorizedError', '[' + currentCase + '] ' + responseName, currentCase)
          }

          // @ts-expect-error
          if (response?.getResponseMessage().toObject().serverError) {
            throw new ServerError('ApiError: SERVER ERROR BODY', '[' + currentCase + '] ' + responseName, currentCase)
          }

          if ((responseCheckProductNotFound && currentCase === productNotFoundCase) || (responseCheckShopNotFound && currentCase === shopNotFoundCase)) {
            const error = new SectionError('ApiError: SECTION ERROR', responseName, currentCase)

            error.response = response?.getResponseMessage()

            throw error
          }

          if (!validCases.includes(currentCase)) {
            const error = new ServerError('ApiError: CASE ERROR', '[' + currentCase + '] ' + responseName, currentCase)

            error.response = response?.getResponseMessage()

            throw error
          }
        }

        return response
      } catch (err: any) {
        if (err.message.includes('timed out')) {
          await nuxt.runWithContext(async () => {
            throw showError({ message: 'Timed out', statusCode: 500, fatal: true })
          })
        }

        if (!responseName.includes('customer')) { // временное решение для скрытия алертов в запросах авторизации
          nuxt.runWithContext(() => {
            useNotify().error({
              title: err.name,
              message: err.message
            })
          })
        }

        throw err
      }
    }
  }

  apiInterceptors.value.push(new ErrorInterceptor())
})
